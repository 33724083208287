import { CurrencyInput } from '@components/CurrencyInput';
import {
  BoxAdornment,
  SelectField,
  TextField,
  Toggle,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { PolicyRuleConfigurationField } from '../../types';
import { minutesToTime, safeParseNumber, timeToMinutes } from './utils';
import LocationSelect, { LocationData } from '@components/LocationSelect';
import { TagField } from '@components/TagField';

type Props = {
  settings: PolicyRuleConfigurationField;
  value: unknown;
  onChange: (value: unknown) => void;
};

export function PolicyRuleField({ settings, value, onChange }: Props) {
  if (settings.type === 'toggle') {
    return (
      <Box mb={1}>
        <BoxAdornment
          leftAdornment={
            <Box style={{ pointerEvents: 'none' }}>
              <Toggle checked={(value as boolean) ?? false} />
            </Box>
          }
          title={settings.name}
          description={settings.helperText}
          onClick={() => onChange?.(!value)}
          style={{ textAlign: 'left' }}
        />
      </Box>
    );
  }

  if (settings.type === 'options') {
    return (
      <SelectField
        label={settings.name}
        helperText={settings.helperText}
        value={value}
        options={settings.options}
        onSelectChange={(e, data) => {
          if (data && typeof data === 'object' && 'value' in data) {
            return onChange(data.value);
          }

          return onChange(null);
        }}
        fullWidth
      />
    );
  }

  if (settings.type === 'time') {
    return (
      <TextField
        label={settings.name}
        helperText={settings.helperText}
        value={minutesToTime(value as number)}
        onChange={(e) => onChange(timeToMinutes(e.target.value))}
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{ type: 'time' }}
      />
    );
  }

  if (settings.type === 'number') {
    return (
      <TextField
        label={settings.name}
        helperText={settings.helperText}
        value={value}
        onChange={(e) => onChange(safeParseNumber(e.target.value))}
        fullWidth
        InputProps={{ type: 'number', inputProps: { maxlength: 5 } }}
      />
    );
  }

  if (settings.type === 'currency') {
    return (
      <CurrencyInput
        helperText={settings.helperText}
        label={settings.name}
        value={value as string | number}
        fullWidth
        maxLength={12}
        onValueChange={(e) => onChange(e.floatValue)}
      />
    );
  }

  if (settings.type === 'place') {
    return (
      <LocationSelect
        value={value as LocationData}
        onChange={(data) => onChange(data)}
        label={settings.name}
        helperText={settings.helperText}
      />
    );
  }

  if (settings.type === 'list') {
    return (
      <TagField
        label={settings.name}
        helperText={settings.helperText}
        value={value as string[]}
        onChange={(newValue) => onChange(newValue)}
      />
    );
  }

  return (
    <TextField
      label={settings.name}
      helperText={settings.helperText}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
    />
  );
}

export default PolicyRuleField;
