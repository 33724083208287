import { z } from 'zod';

export const approvalFlowSchema = z.object({
  id: z.string(),
  name: z.string(),
  condition: z.array(
    z.object({
      conditionType: z.enum(['MANUAL', 'HIERARCHY', 'COST_CENTER_OWNER']),
      value: z.string().optional(),
      level: z.number().optional(),
      autoApprove: z.number().optional(),
    }),
  ),
  description: z.string(),
  approvalType: z.string({
    required_error: 'A opção de tipo de aprovação é obrigatória',
  }),
  flowMode: z.enum(['SEQUENTIAL', 'PARALLEL']),
  criteria: z.array(
    z.object({
      type: z.string(),
      operator: z.string().optional(),
      relationshipCondition: z.string().optional(),
      value: z.string().optional(),
      complementValue: z.string().optional(),
    }),
  ),
  priority: z.number(),
  status: z.enum(['ACTIVE', 'INACTIVE']),
});

export const getApprovalFlowsResponseSchema = z.object({
  approvalFlows: z.array(
    approvalFlowSchema.pick({
      id: true,
      name: true,
      description: true,
      priority: true,
      approvalType: true,
      status: true,
    }),
  ),
});

export const getApprovalFlowByIdRequestSchema = approvalFlowSchema.pick({
  id: true,
});

export const getApprovalFlowByIdResponseSchema = approvalFlowSchema;

export const createApprovalFlowBasicInputSchema = approvalFlowSchema.pick({
  name: true,
  description: true,
  priority: true,
});

export const createApprovalFlowCriteriaInputSchema = approvalFlowSchema.pick({
  name: true,
  description: true,
  priority: true,
  criteria: true,
});

export const createApprovalFlowInputSchema = approvalFlowSchema.pick({
  name: true,
  description: true,
  approvalType: true,
  condition: true,
  criteria: true,
  flowMode: true,
  priority: true,
});

export const updateApprovalFlowInputSchema = approvalFlowSchema
  .pick({ id: true })
  .and(createApprovalFlowInputSchema);

export const toggleApprovalFlowInputSchema = approvalFlowSchema.pick({
  id: true,
});

export const updateApprovalFlowPriorityInputSchema = z.object({
  priority: z.array(z.string()),
});

export const updateApprovalFlowStatusInputSchema = approvalFlowSchema.pick({
  id: true,
});

export const deleteApprovalFlowByIdInputSchema = approvalFlowSchema.pick({
  id: true,
});

export type GetApprovalFlowsResponse = z.infer<
  typeof getApprovalFlowsResponseSchema
>;

export type GetApprovalFlowByIdRequest = z.infer<
  typeof getApprovalFlowByIdRequestSchema
>;

export type GetApprovalFlowByIdResponse = z.infer<
  typeof getApprovalFlowByIdResponseSchema
>;

export type CreateApprovalFlowInput = z.infer<
  typeof createApprovalFlowInputSchema
>;

export type CreateApprovalFlowBasicInput = z.infer<
  typeof createApprovalFlowBasicInputSchema
>;

export type CreateApprovalFlowCriteriaInput = z.infer<
  typeof createApprovalFlowCriteriaInputSchema
>;

export type UpdateApprovalFlowInput = z.infer<
  typeof updateApprovalFlowInputSchema
>;

export type UpdateApprovalFlowPriorityInput = z.infer<
  typeof updateApprovalFlowPriorityInputSchema
>;

export type ToggleApprovalFlowStatusInput = z.infer<
  typeof toggleApprovalFlowInputSchema
>;

export type DeleteApprovalFlowByIdInput = z.infer<
  typeof deleteApprovalFlowByIdInputSchema
>;
