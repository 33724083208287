import { trpc } from '@api/bff/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { ConditionFieldResponse } from 'bff/src/routers/expense-configurations';
import { TRPCSelectQuery } from '../components/ConditionQuerySelect';
import { FieldTypes, SelectOption } from '../types';

export type ConditionFieldConfig = Omit<ConditionFieldResponse, 'options'> & {
  options?: SelectOption[] | TRPCSelectQuery;
};

export const useDefaultFieldConfiguration = () => {
  const company = useSelectedCompany();

  const fields: Record<FieldTypes, ConditionFieldConfig> = {
    branch: {
      label: 'Filial',
      type: 'select',
      options: trpc.getSubisidiaryOptions,
    },
    area: {
      label: 'Área',
      type: 'select',
      options: trpc.getAreaOptions,
    },
    position: {
      label: 'Cargo',
      type: 'select',
      options: trpc.getJobPositionOptions,
    },
    costCenterResponsible: {
      label: 'Resp. Centro de Custo',
      type: 'select',
      options: trpc.getUserOptions,
    },
    project: {
      label: 'Projeto',
      type: 'select',
      options: trpc.getProjectOptions,
    },
    user: {
      label: 'Usuário',
      type: 'select',
      options: trpc.getUserOptions,
    },
    travelType: {
      label: 'Tipo de Viagem',
      type: 'select',
      options: [
        { label: 'Nacional', value: 'nacional' },
        { label: 'Internacional', value: 'internacional' },
      ],
    },
    company: {
      label: 'Empresa',
      type: 'select',
      options: [
        {
          label: company?.selectedCompany?.name,
          value: company?.selectedCompany.id,
        },
      ],
      defaultValue: company?.selectedCompany.id,
      isDisabled: true,
    },
    policyResult: {
      label: 'Resultado da Política',
      type: 'select',
      options: [
        { label: 'Conformidade', value: 'conformidade' },
        { label: 'Não Conformidade', value: 'nao_conformidade' },
      ],
    },
    totalRvValue: {
      label: 'Valor total da RV',
      type: 'currency',
    },
  };

  return fields;
};
