import { ConditionFieldConfig } from '@components/CriteriaFields/hooks/useDefaultFieldConfiguration';
import {
  CriteriaFieldsProps,
  SelectOption,
} from '@components/CriteriaFields/types';
import {
  PillButton,
  SelectField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Grid } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { ConditionValueField } from '../ConditionValueField';

type Props = {
  name: string;
  index: number;
  fixed: CriteriaFieldsProps['static'];
  formKeys: CriteriaFieldsProps['formKeys'];
  conditionFieldOptions: SelectOption[];
  conditionFieldsConfig: ConditionFieldConfig[];
  addedConditionFields: string[];
  avoidRepeatFields?: boolean;
  onRemove: () => void;
};

const conditionOptions = [
  { label: 'E', value: 'and' },
  { label: 'OU', value: 'or' },
];

const extendedOperatorOptions = [
  { label: 'igual a', value: 'equal' },
  { label: 'maior que', value: 'greater' },
  { label: 'maior ou igual a', value: 'greater_equal' },
  { label: 'diferente de', value: 'not_equal' },
  { label: 'menor que', value: 'less' },
  { label: 'menor ou igual a', value: 'less_equal' },
];

const standardOperatorOptions = [
  { label: 'igual a', value: 'equal' },
  { label: 'diferente de', value: 'not_equal' },
];

const getOption = (options: SelectOption[], value: string) => {
  return options.find((o) => o.value === value)?.label || '-';
};

const ConditionRow = ({
  index,
  name,
  fixed,
  onRemove,
  conditionFieldOptions,
  conditionFieldsConfig,
  addedConditionFields,
  avoidRepeatFields,
  formKeys,
}: Props) => {
  const { control } = useFormContext();

  const conditionLinker = useController({
    name: `${name}.${formKeys?.condition || 'condition'}`,
    control,
  });

  const conditionField = useController({
    name: `${name}.${formKeys?.field || 'field'}`,
    control,
  });

  const conditionOperator = useController({
    name: `${name}.${formKeys?.operator || 'operator'}`,
    control,
  });

  const conditionValue = useController({
    name: `${name}.${formKeys?.value || 'value'}`,
    control,
  });

  function getConditionFieldType(field: string) {
    return conditionFieldsConfig.find((f) => f.key === field)?.type;
  }

  function getConditionsFieldsOptions() {
    if (!avoidRepeatFields) return conditionFieldOptions;

    return conditionFieldOptions.filter(
      (field) =>
        !addedConditionFields?.includes(field.value) ||
        field.value === conditionField.field.value,
    );
  }

  return (
    <Grid container spacing={2} mb={2} alignItems="center">
      <Grid item xs={0.5}>
        <Typography variant="body4">{index + 1}</Typography>
      </Grid>

      <Grid item xs={fixed?.condition ? 1 : 2}>
        {index === 0 ? (
          <Typography variant="body4">Quando</Typography>
        ) : fixed?.condition ? (
          <Typography variant="body4">
            {getOption(conditionOptions, fixed?.condition)}
          </Typography>
        ) : (
          <SelectField
            value={conditionLinker.field.value || fixed?.condition}
            onSelectChange={(_, selectedItem) => {
              conditionLinker.field.onChange(selectedItem?.value);
            }}
            options={conditionOptions}
            label="Selecione"
            fullWidth
            error={conditionLinker.fieldState.invalid}
            helperText={conditionLinker.fieldState.error?.message}
          />
        )}
      </Grid>

      <Grid item xs={3}>
        <SelectField
          value={conditionField.field.value}
          onSelectChange={(_, selectedItem) => {
            conditionField.field.onChange(selectedItem?.value);
            const config = conditionFieldsConfig.find(
              (f) => f.key === selectedItem?.value,
            );
            conditionValue.field.onChange(config?.defaultValue || null);
          }}
          options={getConditionsFieldsOptions()}
          label="Selecione um campo"
          fullWidth
          error={conditionField.fieldState.invalid}
          helperText={conditionField.fieldState.error?.message}
        />
      </Grid>

      <Grid item xs={fixed?.operator ? 1 : 2.5}>
        {fixed?.operator ? (
          <Typography
            variant="body4"
            style={{ width: '100%', textAlign: 'center' }}
          >
            {getOption(extendedOperatorOptions, fixed?.operator)}
          </Typography>
        ) : (
          <SelectField
            value={conditionOperator.field.value || fixed?.operator}
            onSelectChange={(_, selectedItem) => {
              conditionOperator.field.onChange(selectedItem?.value);
            }}
            options={
              getConditionFieldType(conditionField.field.value) === 'currency'
                ? extendedOperatorOptions
                : standardOperatorOptions
            }
            label="Selecione um operador"
            fullWidth
            error={conditionOperator.fieldState.invalid}
            helperText={conditionOperator.fieldState.error?.message}
          />
        )}
      </Grid>

      <Grid item xs={3}>
        <ConditionValueField
          field={conditionValue.field}
          fieldState={conditionValue.fieldState}
          selectedField={conditionField.field.value}
          fieldsConfig={conditionFieldsConfig}
        />
      </Grid>

      <Grid item xs={1}>
        {index > 0 && (
          <PillButton
            onClick={() => onRemove()}
            variant={'pink'}
            size="small"
            type="secondary"
            icon="IconTrash"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ConditionRow;
