import { trpc } from '@api/bff/client';
import { Autocomplete } from '@components/Autocomplete';
import { Box, TextFieldProps } from '@mui/material';
import { useDebounce } from 'use-debounce';
import emptyState from '../../assets/empty-state.png';

export type LocationData = { id: string; name: string };

type Props = {
  value?: LocationData;
  onChange?: (data: LocationData | null) => void;
} & Pick<TextFieldProps, 'label' | 'helperText'>;

const LocationSelect = ({ value, onChange, helperText, label }: Props) => {
  const [searchText, setSearchText] = useDebounce('', 500);

  const locations = trpc.searchLocations.useQuery(
    {
      keywords: searchText,
    },
    {
      enabled: searchText.length >= 3,
    },
  );

  return (
    <Box>
      <Autocomplete
        label={label}
        helperText={helperText}
        value={value?.id}
        options={
          locations.data?.locations.map((location) => ({
            value: location.id,
            label: location.name,
            icon: 'IconMapPin',
          })) || []
        }
        isLoading={locations.isFetching}
        fixedMessageOption={{
          title: 'Não encontrou o local?',
          caption: 'Informe mais detalhes como número, bairro ou cidade.',
          image: (
            <img
              src={emptyState}
              width={150}
              alt="nenhuma opção"
              style={{ margin: '0 auto' }}
            />
          ),
        }}
        noOptionContent={{
          title: 'Buscar locais',
          caption:
            'Encontre um local por nome ou endereço informando a rua, número, bairro ou cidade.',
          image: (
            <img
              src={emptyState}
              width={150}
              alt="nenhuma opção"
              style={{ margin: '0 auto' }}
            />
          ),
        }}
        onInputChange={(value) => setSearchText(value)}
        onSelectChange={(item) =>
          onChange?.(item ? { id: String(item.value), name: item.label } : null)
        }
        selectedItem={
          value
            ? {
                value: value.id,
                label: value.name,
                icon: 'IconMapPin',
              }
            : null
        }
        disabledFilter
        disableClearable
      />
    </Box>
  );
};

export default LocationSelect;
