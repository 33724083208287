import Popup from '../../popup/popup.component.js';
import SelectComponent from '../../select/select.component.js';
import Select from '../../select/select.component.js';
import DateComponent from '../../date/date.component.js';
import ToggleComponent from '../../toggle/toggle.component.js';
import Pagination from '../../pagination/pagination.componente.js';
import AutoComplete from '../../common/auto-complete/auto-complete.component.js';
import Checkout from '../../quotes/checkout/checkout.componente.js';
import TextBox from '../../textbox/textbox.component.js';
import Search from './dashboard-search/search.component.js';
import { enums } from '../../../utils/enums.js';
import { utils } from '@legacy-utils/utils';
import { travelEnums } from '@legacy-utils//enums.js';
import { formatterUtil } from '@legacy-utils//formatter.js';
import { Icons, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import RequestTravelOffline from '@components/RequestTravel/RequestTravelOffline';
import { useTravelItemStore } from '../../../../store/RequestTravelItem.store';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import { useTravelPermissions } from '@shared/hooks/useTravelPermissions';

var DashboardTemplate = {
  main: ({
    requests,
    summary,
    isApproval,
    onCardClick,
    onOpenRequestClick,
    onRequestNumberChange,
    onRequestKeyPress,
    requestSearcher,
    onSeachByNumberClick,
    onFilterOpenCloseClick,
    onFilterClearClick,
    isFilterOpened,
    onFilterApplyClick,
    orderByProps,
    statusProps,
    filterProductTypesProps,
    travelTypeProps,
    initialDateProps,
    finalDateProps,
    travelerOnlyProps,
    onlySonsProps,
    filterByPolicyProps,
    filterByOutPolicyProps,
    companiesRequestSearcherProps,
    companiesFilterSelectProps,
    companiesProps,
    isLoading,
    onOrderClick,
    pagination,
    onPageClick,
    showOptions,
    requesterEvents,
    travelerEvents,
    requesterAutoCompleteInputText,
    travelerAutoCompleteInputText,
    travelersDataSources,
    requestersDataSources,
    userTravelProfile,
    orderByFromResults,
    displayCheckout,

    isExternalReserveOpened,
    onExternalReserveOpenCloseClick,
    onExternalReserveApplyClick,
    productTypeProps,
    providerProps,
    locator,
    onLocatorChange,
    onShowHideOptionClick,
    onCloseCheckoutClick,
    agencyAndCompany,
    checkoutProps,
    isCheckoutLoading,
    disabledOkButtonExternalReserve,
    validateExternalReserve,
    externalBooking,
    eventsAdd,

    isAgency,
    onCheckboxChange,
    isActive,
    serviceRequestSelected,
    aprovedEvents,
    popupAprovedData,
    refuseEvents,
    popupRecuseJustificationData,
    descriptionJustification,
    showValidationsfieldsJustification,
    onRefreshClick,
    updateAt,
    selectUpdateParamiters,
    intervalOption,
    filters,

    viewAllBox,
    onViewModeClick,
  }) => {
    let getMoneyFormatted = utils.formatters.money.getFormatted;
    let getCurrencySymbol = utils.converters.money.getCurrencySymbol;

    const airProductName = 'Aéreo';
    const hotelProductName = 'Hotel';
    const vehicleProductName = 'Veículo';

    const updateContextId = useTravelStore((state) => state.updateContextId);
    const updateID = useTravelItemStore((state) => state.updateID);
    const { enableVehicleOffline } = useTravelPermissions();
    const modifiedOrderByFromResults = !isAgency ? 2 : orderByFromResults;

    return (
      <div className="exon-requests-travel-dashboard">
        <div className="exon-requests-travel-header">
          <div className="exon-requests-travel-header_start">
            <div className="exon-requests-travel-header-title hide">
              <span className="exon-requests-travel-header-title_1">
                Viagens{' '}
              </span>
              <i className="exon-icon-requests-travel exon-icon-play-filled_enabled"></i>
              <span className="exon-requests-travel-header-title_2">
                {isApproval ? ' Aprovações' : ' Requisições'}
              </span>
            </div>
            <div className="exon-requests-travel-header-input">
              <Search
                isAgency={isAgency}
                companiesRequestSearcherProps={companiesRequestSearcherProps}
                requestSearcher={requestSearcher}
                onSeachByNumberClick={onSeachByNumberClick}
                onRequestKeyPress={onRequestKeyPress}
                onRequestNumberChange={onRequestNumberChange}
              />
              <button
                onClick={onFilterOpenCloseClick}
                className="btn btn-sm btn-round btn-success exon-requests-travel-header_btn"
              >
                <i className="exon-icon-requests-travel exon-icon-filter-white-unfilled_disabled"></i>
                Filtrar
                {filters.count ? (
                  <i className="exon-requests-travel-filter-count">
                    {filters.count}
                  </i>
                ) : (
                  ''
                )}
              </button>
              <span className="exon-requests-period">
                Periodo de{' '}
                {initialDateProps.data &&
                  utils.formatters.date.dateObjToString(
                    initialDateProps.data,
                  )}{' '}
                até{' '}
                {finalDateProps.data &&
                  utils.formatters.date.dateObjToString(finalDateProps.data)}
              </span>
            </div>
          </div>
          <div className="exon-requests-travel-header_end">
            {updateAt && (
              <span className="exon-requests-last-update">
                Atualizado em: {updateAt.toLocaleString()}
              </span>
            )}
            {intervalOption && (
              <Select
                data={selectUpdateParamiters.data}
                externalOptions={selectUpdateParamiters.options}
                placeholder={selectUpdateParamiters.placeholder}
                required={true}
              ></Select>
            )}
            <span
              className="exon-icon exon-icon-double-update-unfilled_enabled exon-row-aling-itens-center"
              onClick={() => onRefreshClick()}
            ></span>
            <div
              onClick={onOrderClick}
              className="exon-requests-travel-header-order"
            >
              <i className="exon-icon exon-icon-order-filled_disabled"></i>
            </div>
          </div>
        </div>

        <hr />

        {isLoading && (
          <div className="exon-box-common-loading">
            <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
            <p>
              Por favor aguarde!
              <br />
              Estamos carregando as informações...
            </p>
          </div>
        )}
        {!isLoading && (
          <div>
            <div className="exon-requests-travel-cards">
              {!isApproval &&
                summary &&
                summary.groups &&
                summary.groups.map((card, index) => {
                  return card.reference != 'integration_error' ? (
                    <div
                      key={index}
                      className={
                        card.active
                          ? 'exon-dashboard-card-active '
                          : 'exon-dashboard-card'
                      }
                      onClick={() => {
                        if (onCardClick) onCardClick(card.status, index);
                      }}
                    >
                      <div className="exon-dashboard-card_title">
                        {card.name}
                      </div>
                      <div className="exon-dashboard-card_value">
                        {card.count}
                      </div>
                    </div>
                  ) : (
                    isAgency && viewAllBox && (
                      <div
                        key={index}
                        className={
                          card.active
                            ? 'exon-dashboard-card-active '
                            : 'exon-dashboard-card'
                        }
                        onClick={() => {
                          if (onCardClick) onCardClick(card.status, index);
                        }}
                      >
                        <div className="exon-dashboard-card_title">
                          {card.name}
                        </div>
                        <div className="exon-dashboard-card_value">
                          {card.count}
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
            {isAgency && (
              <span
                className="exon-dashboard-card-view-more"
                onClick={() => onViewModeClick()}
              >
                {' '}
                {viewAllBox ? 'Ver menos' : 'Ver mais'}{' '}
              </span>
            )}
          </div>
        )}
        {!isLoading && (!requests || requests.length == 0) && (
          <div className={'exon-no-data-box'}>
            <div>
              <span className="exon-icon exon-icon-noData-unfilled_disabled"></span>
              <p>Nenhum resultado encontrado!</p>
            </div>
          </div>
        )}
        {!isLoading && requests && requests.length > 0 && (
          <div>
            <div className="exon-booking-box-body">
              <table className="table">
                <thead>
                  <tr>
                    {isApproval && <th></th>}
                    <th title="Requisição de Viagem">RV</th>
                    <th className="exon-booking-box-body-table_tr_justify">
                      Status
                    </th>
                    {isAgency && <th>Empresa</th>}
                    <th>Solicitante</th>
                    <th>Viajantes</th>
                    <th>Tipo de produto</th>
                    <th></th>
                    <th>Tipo da viagem</th>
                    <th>Descrição</th>
                    <th>Valor</th>
                    <th>
                      {
                        travelEnums.orderByDateDash.filter(function (item) {
                          return (
                            item.id === (!isAgency ? 2 : orderByFromResults)
                          );
                        })[0].text
                      }
                    </th>
                    <th className="exon-booking-box-body-table_tr_justify">
                      Violações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {requests &&
                    requests.map((request, index) => {
                      const hasFlashPayment = request.paymentTypes?.includes(
                        enums.paymentType.flashPay,
                      );

                      let productTypes = request.productType;

                      if (request.productTypes) {
                        const splittedProductTypes =
                          request.productTypes.reduce((items, productType) => {
                            switch (productType) {
                              case enums.products.namedType.airBooking:
                                !items.includes(airProductName) &&
                                  items.push(airProductName);
                                break;
                              case enums.products.namedType.hotelBooking:
                                !items.includes(hotelProductName) &&
                                  items.push(hotelProductName);
                                break;
                              case enums.products.namedType.vehicleBooking:
                                !items.includes(vehicleProductName) &&
                                  items.push(vehicleProductName);
                                break;
                            }
                            return items;
                          }, []);

                        productTypes = splittedProductTypes.join(
                          ' + ',
                          splittedProductTypes,
                        );
                      }

                      return (
                        <tr
                          className="exon-booking-box-body-table_tr"
                          key={index}
                          onClick={(e) => {
                            updateContextId(request.rootContextId);
                            updateID(request.id);
                            onOpenRequestClick(
                              e,
                              request.id,
                              request.rootContextId,
                            );
                          }}
                        >
                          {isApproval && (
                            <th>
                              {hasFlashPayment ? (
                                <Tooltip
                                  arrow
                                  title="Não é possivel realizar a aprovação em massa de RV's com Pagamento Flash."
                                  placement="top-start"
                                >
                                  <input
                                    type="checkbox"
                                    disabled={true}
                                    onChange={(ev) =>
                                      onCheckboxChange(ev, request.id)
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <input
                                  type="checkbox"
                                  onChange={(ev) =>
                                    onCheckboxChange(ev, request.id)
                                  }
                                />
                              )}
                            </th>
                          )}
                          <td>{request.id}</td>
                          <td className="exon-booking-box-body-table_tr_justify">
                            <span className="exon-booking-box-body-table_status">
                              {request.status.name}
                            </span>
                          </td>
                          {isAgency && (
                            <td title={`Context: ${request.contextId}`}>
                              {request.companyName}
                            </td>
                          )}
                          <td>{request.requester && request.requester.name}</td>
                          <td>
                            {request.travelers
                              .map((traveler) => traveler.firstName)
                              .join(', ')}
                          </td>
                          <td>{productTypes}</td>
                          <td>
                            {request.dataOrigin ==
                              enums.requestDataOrigin.manual && (
                              <span className="badge">Via Formulário</span>
                            )}
                            {request.dataOrigin ==
                              enums.requestDataOrigin.externalBooking && (
                              <span className="badge">External</span>
                            )}
                          </td>
                          <td>
                            {request.isDomestic ? 'Nacional' : 'Internacional'}
                          </td>
                          <td>
                            {formatterUtil.stringReduce(
                              request.description,
                              30,
                            )}
                          </td>
                          <td>
                            <span className="exon-split-iconography">
                              {request.priceSummary.totalValue
                                ? `${getCurrencySymbol(
                                    request.priceSummary.totalValue
                                      .currencyCode,
                                  )} ${getMoneyFormatted(
                                    request.priceSummary.totalValue.value,
                                    request.priceSummary.totalValue
                                      .currencyCode,
                                  )}`
                                : '--'}
                              {request?.splitEnabled && (
                                <span
                                  title="Custos divididos"
                                  alt="Custos divididos"
                                >
                                  <Icons
                                    name="IconArrowsSplit2"
                                    className="exon-split-list-icon"
                                    fill="transparent"
                                    size={16}
                                  />
                                </span>
                              )}
                            </span>
                          </td>
                          <td>
                            {modifiedOrderByFromResults == 1
                              ? formatterUtil.dateTimeFormat(
                                  formatterUtil.dateObjToDate(
                                    request.createDate,
                                  ),
                                )
                              : modifiedOrderByFromResults == 2
                              ? formatterUtil.dateTimeFormat(
                                  formatterUtil.dateObjToDate(
                                    request.expiresIn,
                                  ),
                                )
                              : formatterUtil.dateTimeFormat(
                                  formatterUtil.dateObjToDate(
                                    request.embarkOrCheckinDate,
                                  ),
                                )}
                          </td>
                          <td className="exon-booking-box-body-table_tr_justify">
                            <i
                              className={
                                'exon-icon ' +
                                (request.inPolicy
                                  ? 'exon-icon-check-filled_enabled'
                                  : 'exon-icon-warning-filled_enabled-red')
                              }
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <Pagination
              itemsQuantity={pagination.totalResults}
              itemsPerPage={pagination.itemsPerPage}
              onPageClick={onPageClick}
              currentPage={pagination.currentPage}
            />
          </div>
        )}
        {!isApproval && !isLoading && (
          <div className="exon-travel-requests-box-actions">
            <div className="exon-requests-travel-header_btn ">
              <div className={'exon-select ' + (!showOptions ? 'hide' : '')}>
                <div
                  className={'exon-outside-options-background-mask '}
                  onClick={eventsAdd.onShowHideOptionClicked}
                ></div>
                <ul
                  className={'exon-select-itens exon-select__list '}
                  tabIndex="1"
                >
                  <li
                    className="exon-select__list-item"
                    onClick={eventsAdd.onCreateRvOnlineClicked}
                  >
                    <label className="exon-select__list-item-box">
                      Cotação de voos e hotéis
                    </label>
                  </li>
                  {isAgency &&
                    userTravelProfile == enums.userTravelProfile.consultant && (
                      <li className="exon-select__list-item">
                        <label
                          className="exon-select__list-item-box"
                          onClick={eventsAdd.onExternalReserveOpenCloseClicked}
                        >
                          Criar por localizador
                        </label>
                      </li>
                    )}
                  <li className="exon-select__list-item">
                    {enableVehicleOffline ? (
                      <label className="exon-select__list-item-box">
                        <RequestTravelOffline isListItem />
                      </label>
                    ) : (
                      <label
                        className="exon-select__list-item-box"
                        onClick={eventsAdd.onCreateRvOffline}
                      >
                        Requisição via formulário
                      </label>
                    )}
                  </li>
                </ul>
              </div>
              <div className="exon-requests-travel-header-input">
                <button
                  onClick={onShowHideOptionClick}
                  className="btn btn-sm btn-round btn-success exon-requests-travel-header_btn"
                >
                  <span className="exon-travel-request-pluss">+</span>
                  Adicionar
                </button>
              </div>
            </div>
          </div>
        )}
        {isApproval &&
          [
            enums.userTravelProfile.approver,
            enums.userTravelProfile.consultant,
          ].includes(userTravelProfile) && (
            <div className="exon-requests-travel-header">
              <div className="exon-requests-travel-header_btn ">
                <div className="exon-requests-travel-header-input">
                  <button
                    disabled={isActive}
                    onClick={() =>
                      aprovedEvents.onApproveRequestClicked(
                        serviceRequestSelected,
                      )
                    }
                    className="btn btn-sm btn-round btn-approval exon-requests-travel-header_btn"
                  >
                    <i className="exon-icon exon-icon-check-white-unfilled_enabled"></i>{' '}
                    Aprovar
                  </button>
                  <button
                    disabled={isActive}
                    onClick={() =>
                      refuseEvents.onRefuseRequestClicked(
                        serviceRequestSelected,
                      )
                    }
                    className="btn btn-sm btn-round btn-default exon-requests-travel-header_btn"
                  >
                    <i className="exon-icon exon-icon-close-white-unfilled_enabled"></i>{' '}
                    Recusar
                  </button>
                </div>
              </div>
            </div>
          )}
        {isFilterOpened && (
          <Popup
            okButtonLabel={'Aplicar'}
            cancelButtonLabel={'Limpar Tudo'}
            title={''}
            cancelButtonClick={onFilterClearClick}
            closeButtonClick={onFilterOpenCloseClick}
            exonStyle={true}
            okButtonClick={onFilterApplyClick}
          >
            <DashboardTemplate.filter
              isAgency={isAgency}
              companiesFilterSelectProps={companiesFilterSelectProps}
              isApproval={isApproval}
              orderByProps={orderByProps}
              statusProps={statusProps}
              filterProductTypesProps={filterProductTypesProps}
              requesterEvents={requesterEvents}
              travelerEvents={travelerEvents}
              requesterAutoCompleteInputText={requesterAutoCompleteInputText}
              travelerAutoCompleteInputText={travelerAutoCompleteInputText}
              travelersDataSources={travelersDataSources}
              requestersDataSources={requestersDataSources}
              travelTypeProps={travelTypeProps}
              initialDateProps={initialDateProps}
              finalDateProps={finalDateProps}
              travelerOnlyProps={travelerOnlyProps}
              onlySonsProps={onlySonsProps}
              filterByPolicyProps={filterByPolicyProps}
              filterByOutPolicyProps={filterByOutPolicyProps}
              filters={filters}
            />
          </Popup>
        )}

        {isExternalReserveOpened && (
          <Popup
            okButtonLabel={'Aplicar'}
            cancelButtonLabel={'Limpar Tudo'}
            title={''}
            cancelButtonClick={onExternalReserveOpenCloseClick}
            exonStyle={true}
            disabledOkButton={disabledOkButtonExternalReserve}
            okButtonClick={onExternalReserveApplyClick}
          >
            <DashboardTemplate.externalReserve
              productTypeProps={productTypeProps}
              providerProps={providerProps}
              locator={locator}
              onLocatorChange={onLocatorChange}
              companiesProps={companiesProps}
              validateExternalReserve={validateExternalReserve}
            />
          </Popup>
        )}
        {popupAprovedData.show && (
          <div className="exon-box-popup-decision">
            <DashboardTemplate.popup.approval
              data={popupAprovedData.description}
              events={popupAprovedData.events}
            />
          </div>
        )}
        {displayCheckout && (
          <DashboardTemplate.popUpCheckout
            checkoutProps={checkoutProps}
            onCloseClick={onCloseCheckoutClick}
            agencyAndCompany={agencyAndCompany}
            isCheckoutLoading={isCheckoutLoading}
            externalBooking={externalBooking}
          />
        )}
        {popupRecuseJustificationData.show && (
          <div className="exon-box-popup-justification">
            <DashboardTemplate.popup.reproved
              data={popupRecuseJustificationData.description}
              events={popupRecuseJustificationData.events}
              descriptionJustification={descriptionJustification}
              showValidationsfieldsJustification={
                showValidationsfieldsJustification
              }
            />
          </div>
        )}
      </div>
    );
  },

  filter: ({
    isAgency,
    companiesFilterSelectProps,
    isApproval,
    orderByProps,
    statusProps,
    filterProductTypesProps,
    requesterEvents,
    travelerEvents,
    requesterAutoCompleteInputText,
    travelerAutoCompleteInputText,
    travelersDataSources,
    requestersDataSources,
    travelTypeProps,
    initialDateProps,
    finalDateProps,
    travelerOnlyProps,
    onlySonsProps,
    filterByPolicyProps,
    filterByOutPolicyProps,
    filters,
  }) => {
    let showCompanyFilter = isAgency;

    return (
      <div className="exon-box-popop">
        <div>
          <h2>Filtros</h2>
        </div>

        <div className="exon-requests-travel-dashboard-filter">
          {showCompanyFilter && (
            <div className="exon-requests-travel-dashboard-filter-item">
              <div className="exon-requests-travel-dashboard-filter-item_label">
                Empresa
              </div>
              <div className="exon-requests-travel-dashboard-filter_input">
                <Select
                  data={companiesFilterSelectProps.data}
                  externalOptions={companiesFilterSelectProps.options}
                  placeholder={companiesFilterSelectProps.placeholder}
                  showSearch={true}
                ></Select>
              </div>
            </div>
          )}
          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Ordenar Por
            </div>
            <div className="exon-requests-travel-dashboard-filter_input">
              <SelectComponent
                data={orderByProps.data}
                externalOptions={orderByProps.options}
                placeholder="Selecione"
              ></SelectComponent>
            </div>
          </div>
          {!isApproval && (
            <div className="exon-requests-travel-dashboard-filter-item">
              <div className="exon-requests-travel-dashboard-filter-item_label">
                Status
              </div>
              <div className="exon-requests-travel-dashboard-filter_input">
                <SelectComponent
                  data={statusProps.data}
                  externalOptions={statusProps.options}
                  placeholder="Selecione"
                ></SelectComponent>
              </div>
            </div>
          )}

          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Produto
            </div>
            <div className="exon-requests-travel-dashboard-filter_input">
              <SelectComponent
                data={filterProductTypesProps.data}
                externalOptions={filterProductTypesProps.options}
                placeholder="Selecione"
              ></SelectComponent>
            </div>
          </div>

          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Solicitante
            </div>
            <div
              className="exon-requests-travel-dashboard-filter_input"
              title={
                showCompanyFilter && !filters.contextId
                  ? 'Selecione uma empresa'
                  : ''
              }
            >
              <AutoComplete
                inputText={requesterAutoCompleteInputText}
                placeholder={'Digite no mínimo 3 letras.'}
                dataSource={requestersDataSources}
                disabled={
                  showCompanyFilter && !filters.contextId ? true : false
                }
                required={false}
                onChange={requesterEvents.onAutoCompleteChanged}
                onInputChange={requesterEvents.onAutoCompleteInputChanged}
              />
            </div>
          </div>

          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Viajante
            </div>
            <div
              className="exon-requests-travel-dashboard-filter_input"
              title={
                showCompanyFilter && !filters.contextId
                  ? 'Selecione uma empresa'
                  : ''
              }
            >
              <AutoComplete
                inputText={travelerAutoCompleteInputText}
                placeholder={'Digite no mínimo 3 letras.'}
                dataSource={travelersDataSources}
                disabled={
                  showCompanyFilter && !filters.contextId
                    ? true
                    : travelerOnlyProps.data
                }
                required={false}
                onChange={travelerEvents.onAutoCompleteChanged}
                onInputChange={travelerEvents.onAutoCompleteInputChanged}
              />
            </div>
          </div>

          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Tipo
            </div>
            <div className="exon-requests-travel-dashboard-filter_input">
              <SelectComponent
                data={travelTypeProps.data}
                externalOptions={travelTypeProps.options}
                placeholder="Selecione"
              ></SelectComponent>
            </div>
          </div>
          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Período
            </div>
            <div className="exon-requests-travel-dashboard-filter_input exon-filter-dates">
              <div className="exon-requests-travel-dashboard-filter_input-date exon-requests-travel-dashboard-filter_input-date-initial">
                <DateComponent
                  data={initialDateProps.data}
                  externalOptions={initialDateProps.options}
                  endDate={initialDateProps.endDate}
                  placeholder={initialDateProps.placeholder}
                  required={true}
                />
              </div>
              <div className="exon-requests-travel-dashboard-filter_input-date">
                <DateComponent
                  data={finalDateProps.data}
                  endDate={finalDateProps.endDate}
                  startDate={finalDateProps.startDate}
                  externalOptions={finalDateProps.options}
                  placeholder={finalDateProps.placeholder}
                  required={true}
                />
              </div>
            </div>
          </div>
          {!isApproval && (
            <div className="exon-requests-travel-dashboard-filter-item">
              <div className="exon-requests-travel-dashboard-filter-item_label exon-filter-item_label-lg">
                Apenas viagens onde sou o viajante
              </div>
              <div className="">
                <ToggleComponent
                  checked={travelerOnlyProps.data}
                  externalOptions={travelerOnlyProps.options}
                />
              </div>
            </div>
          )}
          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label exon-filter-item_label-lg">
              Apenas itens / filhos da Requisição de Viagens
            </div>
            <div className="">
              <ToggleComponent
                checked={onlySonsProps.data}
                externalOptions={onlySonsProps.options}
              />
            </div>
          </div>
          {isApproval && (
            <div className="exon-requests-travel-dashboard-filter-item">
              <div className="exon-requests-travel-dashboard-filter-item_label exon-filter-item_label-lg">
                Filtrar por política
              </div>
              <div className="">
                <ToggleComponent
                  checked={filterByPolicyProps.data}
                  externalOptions={filterByPolicyProps.options}
                />
              </div>
            </div>
          )}
          {isApproval && (
            <div className="exon-requests-travel-dashboard-filter-item">
              <div className="exon-requests-travel-dashboard-filter-item_label exon-filter-item_label-lg">
                Apenas viagens fora da política
              </div>
              <div className="">
                <ToggleComponent
                  checked={filterByOutPolicyProps.data}
                  externalOptions={filterByOutPolicyProps.options}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },

  popup: {
    approval: ({ data, events }) => {
      return (
        <Popup
          okButtonLabel={'Confirmar'}
          cancelButtonLabel={'Cancelar'}
          title={null}
          exonStyle={true}
          cancelButtonClick={events.cancel}
          okButtonClick={events.confirm}
        >
          <span className="exon-icon exon-icon-popup-info-unfilled_enabled"></span>
          <p>{data}</p>
        </Popup>
      );
    },

    reproved: ({
      data,
      events,
      descriptionJustification,
      showValidationsfieldsJustification,
    }) => {
      return (
        <Popup
          okButtonLabel={'Confirmar'}
          okButtonClick={events.confirm}
          cancelButtonLabel={'Cancelar'}
          cancelButtonClick={events.cancel}
          title={null}
          exonStyle={true}
        >
          <span className="exon-icon exon-icon-popup-info-unfilled_enabled"></span>
          <p>{data}</p>

          <div
            className={
              'exon-box-popup-justification-container exon-field' +
              ((descriptionJustification == null ||
                descriptionJustification == '') &&
              showValidationsfieldsJustification
                ? ' exon-alert-border'
                : '')
            }
          >
            <label className="exon-label-field">
              {' '}
              Para seguir com essa reprovação, justifique:{' '}
            </label>

            <textarea
              className="exon-box-popup-justification-textarea"
              type="readonly"
              placeholder="Digite a justificativa da reprovação"
              onChange={(e) => events.onChangeDescription(e.target.value)}
            ></textarea>
            {(descriptionJustification == null ||
              descriptionJustification == '') &&
              showValidationsfieldsJustification && (
                <span className="exon-msg-alert-field">campo obrigatório</span>
              )}
          </div>
        </Popup>
      );
    },
  },

  externalReserve: ({
    productTypeProps,
    providerProps,
    locator,
    companiesProps,
    onLocatorChange,
    validateExternalReserve,
  }) => {
    return (
      <div className="exon-box-popop">
        <div>
          <h2>Reserva Externa</h2>
        </div>

        <div className="exon-requests-travel-dashboard-filter">
          <div className="exon-box-popup-agency-content">
            <label className=""></label>
          </div>
          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Cliente
            </div>
            <div className="exon-requests-travel-dashboard-filter_input">
              <Select
                data={companiesProps.data}
                externalOptions={companiesProps.options}
                placeholder={companiesProps.placeholder}
                forceValidation={validateExternalReserve}
                required={true}
                showSearch={true}
              />
            </div>
          </div>
          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Produto
            </div>
            <div className="exon-requests-travel-dashboard-filter_input">
              <Select
                data={productTypeProps.data}
                forceValidation={validateExternalReserve}
                externalOptions={productTypeProps.options}
                placeholder="Selecione"
                required={true}
              />
            </div>
          </div>
          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Fornecedor
            </div>
            <div className="exon-requests-travel-dashboard-filter_input">
              <Select
                data={providerProps.data}
                externalOptions={providerProps.options}
                placeholder="Selecione"
                forceValidation={validateExternalReserve}
                required={true}
              />
            </div>
          </div>

          <div className="exon-requests-travel-dashboard-filter-item">
            <div className="exon-requests-travel-dashboard-filter-item_label">
              Localizador
            </div>
            <div className="exon-requests-travel-dashboard-filter_input">
              <TextBox
                className={'dm-grid-filter form-control'}
                value={locator}
                required={true}
                onChange={(e) => onLocatorChange(e)}
                maxLength={20}
                forceValidation={validateExternalReserve}
                placeholder={'Digite o Solicitante'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },

  popUpCheckout: ({
    checkoutProps,
    onCloseClick,
    agencyAndCompany,
    isCheckoutLoading,
    externalBooking,
  }) => {
    return (
      <div className="exon-box-popop-checkout">
        <Popup
          okButtonLabel={null}
          cancelButtonLabel={null}
          title={'Checkout'}
          cancelButtonClick={onCloseClick}
          exonStyle={true}
          okButtonClick={null}
        >
          {isCheckoutLoading && (
            <div className="exon-box-common-loading">
              <span className="exon-icon exon-icon-loader-unfilled_disabled"></span>
              <p>
                Por favor aguarde!
                <br />
                Estamos carregando as informações...
              </p>
            </div>
          )}
          {!isCheckoutLoading && (
            <Checkout
              dataSources={checkoutProps.dataSources}
              data={checkoutProps.data}
              userId={checkoutProps.userId}
              onCloseCheckoutClick={onCloseClick}
              agencyAndCompany={agencyAndCompany}
              importExternalBooking={true}
              externalBooking={externalBooking}
            />
          )}
        </Popup>
      </div>
    );
  },
};

export default DashboardTemplate;
