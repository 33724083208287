import { NotificationCard } from '@flash-tecnologia/hros-web-ui-v2';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CriteriaFields from '@components/CriteriaFields';
import { Section } from '@components/Section';
import { CreateApprovalFlowInput } from 'bff/src/routers/approval-flows';
import { WizardStep } from '@components/Wizard/components/WizardStep';

export const ApprovalCriteriaForm = () => {
  const { watch, control } = useFormContext<CreateApprovalFlowInput>();

  const [showPolicyAlert, setShowPolicyAlert] = useState(false);
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name?.startsWith('criteria') && name?.endsWith('field')) {
        const index = name.split('.')[1];
        if (value.criteria?.[index]?.field === 'policy_result') {
          setShowPolicyAlert(true);
        } else {
          setShowPolicyAlert(false);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <WizardStep
      title="Critérios de aprovação"
      description="Selecione os critérios de aprovação para o fluxo que deseja criar."
    >
      <Section
        title="Critérios de aprovação"
        caption="Acionar fluxo de aprovação quando as seguintes condições forem
          abrangidas."
      >
        {showPolicyAlert && (
          <NotificationCard.Root type={'error'} variant={'outlined'}>
            <NotificationCard.WrapperIconWithText>
              <NotificationCard.Icon iconName={'IconAlertTriangle'} />
              <NotificationCard.WrapperTexts>
                <NotificationCard.Subtitle>
                  A definição do <strong>Resultado da Política</strong> será
                  respeitada quando uma ou mais politicas estiverem vinculadas à
                  este fluxo de aprovação.
                </NotificationCard.Subtitle>
              </NotificationCard.WrapperTexts>
            </NotificationCard.WrapperIconWithText>
          </NotificationCard.Root>
        )}
        <CriteriaFields
          control={control}
          name="criteria"
          fields={[
            { type: 'branch', value: 'branch' },
            { type: 'area', value: 'area' },
            { type: 'position', value: 'position' },
            { type: 'costCenterResponsible', value: 'cost_center_responsible' },
            { type: 'travelType', value: 'travel_type' },
            { type: 'policyResult', value: 'policy_result' },
            { type: 'totalRvValue', value: 'total_rv_value' },
          ]}
          formKeys={{
            condition: 'relationshipCondition',
            field: 'type',
          }}
          enableCompanyCustomFields
        />
      </Section>
    </WizardStep>
  );
};
