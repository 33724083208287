import React, { useEffect } from 'react';
import { CreateApprovalFlowInput } from 'bff/src/routers/approval-flows';
import {
  useFormContext,
  useFieldArray,
  Controller,
  useWatch,
} from 'react-hook-form';
import {
  LinkButton,
  PillButton,
  SelectField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Box, Typography } from '@mui/material';
import { useApprovers } from '../../hooks/useApprovers';

const ApproversFields = () => {
  const { control } = useFormContext<CreateApprovalFlowInput>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'condition',
  });

  const { approvers, refetch } = useApprovers();
  const allConditionTypes = useWatch({
    control,
    name: 'condition',
  });

  useEffect(() => {
    if (
      allConditionTypes?.some(
        (condition) => condition?.conditionType === 'MANUAL',
      )
    ) {
      refetch(); // Buscar aprovadores novamente se necessário
    }
  }, [allConditionTypes, refetch]);

  const conditionTypeOptions = [
    {
      value: 'MANUAL',
      label: 'Escolher Aprovador Manualmente',
      caption: 'Selecione um usuário com perfil de aprovação para aprovar',
    },
    {
      value: 'HIERARCHY',
      label: 'Submeter para Hierarquia',
      caption:
        'Enviar para hierarquia de aprovadores cadastrada a partir do gestor imediato.',
    },
    {
      value: 'COST_CENTER_OWNER',
      label: 'Responsável pelo Centro de Custo',
      caption:
        'Envia para usuário responsável pelo centro de custo associado à despesa ',
    },
  ];

  const getValueOptions = (conditionType: string) => {
    switch (conditionType) {
      case 'COST_CENTER_OWNER':
        return [
          { value: 'nivel1', label: 'Nível 1' },
          { value: 'nivel2', label: 'Nível 2' },
          { value: 'nivel3', label: 'Nível 3' },
        ];
      case 'HIERARCHY':
        return [
          {
            value: 'nivel_unico',
            label: 'Nível Único',
            caption: 'Se A envia para B, o aprovador deve ser B',
          },
          {
            value: 'dois_niveis',
            label: 'Dois Níveis',
            caption:
              'Se A envia para B e B para C, o fluxo de aprovação será B => C',
          },
          {
            value: 'tres_niveis',
            label: 'Três Níveis',
            caption:
              'Se A envia para B, B para C e C para D, o fluxo de aprovação será B => C => D',
          },
          {
            value: 'quatro_niveis',
            label: 'Quatro Níveis',
            caption:
              'Se A envia para B, B para C, C para D e D para E, o fluxo de aprovação será B => C => D => E',
          },
          {
            value: 'cinco_niveis',
            label: 'Cinco Níveis',
            caption:
              'Se A envia para B, B para C, C para D, D para E e E para F, o fluxo de aprovação será B => C => D => E => F',
          },
        ];
      case 'MANUAL':
        return approvers;
      default:
        return [];
    }
  };

  const getValueLabel = (conditionType: string) => {
    switch (conditionType) {
      case 'COST_CENTER_OWNER':
        return 'Nível do Centro de Custo';
      case 'HIERARCHY':
        return 'Nível Hierárquico';
      case 'MANUAL':
        return 'Aprovador';
      default:
        return 'Valor';
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {fields.map((field, index) => {
          const conditionType = allConditionTypes[index]?.conditionType;

          return (
            <Box
              key={field.id}
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr) auto',
                gap: 2,
                alignItems: 'start',
              }}
            >
              <Controller
                name={`condition.${index}.level`}
                control={control}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    fullWidth
                    label="Nível"
                    options={Array.from({ length: fields.length }, (_, i) => ({
                      value: i + 1,
                      label: `Nível ${i + 1}`,
                    }))}
                    onSelectChange={(_, selectedItem) => {
                      field.onChange(selectedItem?.value);
                    }}
                  />
                )}
              />
              <Controller
                name={`condition.${index}.conditionType`}
                control={control}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    fullWidth
                    label="Tipo de Condição"
                    onSelectChange={(_, selectedItem) => {
                      field.onChange(selectedItem?.value);
                    }}
                    options={conditionTypeOptions}
                  />
                )}
              />
              <Controller
                name={`condition.${index}.value`}
                control={control}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    fullWidth
                    label={getValueLabel(conditionType)}
                    options={getValueOptions(conditionType)}
                    onSelectChange={(_, selectedItem) => {
                      field.onChange(selectedItem?.value);
                    }}
                  />
                )}
              />
              {conditionType === 'COST_CENTER_OWNER' ? (
                <Box>
                  <Controller
                    name={`condition.${index}.autoApprove`}
                    control={control}
                    render={({ field }) => (
                      <SelectField
                        {...field}
                        fullWidth
                        label="Aprovar Automaticamente"
                        options={[
                          { value: 'true', label: 'Sim' },
                          { value: 'false', label: 'Não' },
                        ]}
                        onSelectChange={(_, selectedItem) => {
                          field.onChange(selectedItem?.value);
                        }}
                      />
                    )}
                  />
                </Box>
              ) : (
                <Box />
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {index > 0 && (
                  <PillButton
                    onClick={() => remove(index)}
                    variant={'pink'}
                    size="small"
                    type="secondary"
                    icon="IconTrash"
                  />
                )}
              </Box>
            </Box>
          );
        })}
        {fields[fields.length - 1]?.conditionType === 'COST_CENTER_OWNER' && (
          <Typography variant="caption" sx={{ mb: 2, display: 'block' }}>
            Aprovar automaticamente quando solicitante for o responsável pelo
            centro de custo
          </Typography>
        )}
      </Box>
      <Box mt={4}>
        <LinkButton
          variant={'default'}
          onClick={() =>
            append({
              conditionType: 'MANUAL',
              value: '',
              level: fields.length + 1,
            })
          }
        >
          Adicionar novo aprovador
        </LinkButton>
      </Box>
    </Box>
  );
};

export default ApproversFields;
