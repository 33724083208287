import { trpc } from '@api/bff/client';
import { Spinner, Toggle } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { dispatchToast } from '@shared/toast';
import { PolicyStatus } from 'bff/src/routers/policies/enums';
import { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

type Props = {
  policy: { id?: string; status?: PolicyStatus };
};

export const TogglePolicyStatus = ({ policy }: Props) => {
  const updatePolicyStatus = trpc.updatePolicyStatus.useMutation();

  const [isChecked, setIsChecked] = useState(
    policy.status === PolicyStatus.ACTIVE,
  );

  const handleUpdateStatus = useDebouncedCallback(
    async (id: string, value: boolean) => {
      try {
        await updatePolicyStatus.mutateAsync({
          id: id,
          status: value ? PolicyStatus.ACTIVE : PolicyStatus.INACTIVE,
        });
      } catch (err) {
        setIsChecked(!value);

        dispatchToast({
          type: 'error',
          content:
            err?.message ||
            'Ocorreu um erro ao alterar o status da política. Tente novamente',
        });
      }
    },
    300,
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      alignItems="center"
      width={75}
      sx={{
        opacity: updatePolicyStatus.isLoading ? 0.5 : 1,
        pointerEvents: updatePolicyStatus.isLoading ? 'none' : 'auto',
      }}
    >
      <Toggle
        checked={isChecked}
        onChange={async (e) => {
          setIsChecked(e.target.checked);
          handleUpdateStatus(policy.id, e.target.checked);
        }}
      />

      {updatePolicyStatus.isLoading && <Spinner size={16} variant="primary" />}
    </Box>
  );
};
