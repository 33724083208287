import {
  Icons,
  IconTypes,
  TextField,
  TextFieldProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Autocomplete as MuiAutoComplete, Popper } from '@mui/material';

import { AutocompleteLoader, LoaderProps } from './AutocompleteLoader';
import {
  AutocompleteMessageOptions,
  MessageOptionsProps,
} from './AutocompleteMessageOptions';
import { useAutocompleteHelper } from './hooks/useAutocompleteHelper';
import { RenderOption } from './RenderOption';
import * as SC from './styled';

export interface Option {
  label?: string;
  icon?: IconTypes;
  caption?: string;
  value: string | number;
}

export interface FixedMessageOption extends MessageOptionsProps {}

export type AutocompleteProps = {
  noOptionContent?: MessageOptionsProps;
  fixedMessageOption?: FixedMessageOption;
  loaderContent?: LoaderProps;
  label: TextFieldProps['label'];
  options?: Option[];
  isLoading?: boolean;
  error?: boolean;
  disabledFilter?: boolean;
  disableClearable?: boolean;
  selectedItem?: Option;
  value: unknown;
  inputValue?: string;
  helperText?: TextFieldProps['helperText'];
  onSelectChange?: (value?: Option) => void;
  onInputChange?: (value: string, isEmpty: boolean) => void;
  onChange?: (value: string, isEmpty: boolean) => void;
};

export const Autocomplete = ({
  value,
  options,
  label,
  isLoading,
  noOptionContent,
  fixedMessageOption,
  loaderContent,
  disabledFilter,
  disableClearable,
  selectedItem,
  onSelectChange,
  onInputChange,
  error,
  inputValue,
  helperText,
}: AutocompleteProps) => {
  const { optionsCount, getLabelOption, filterOptions, isOptionEqualToValue } =
    useAutocompleteHelper({
      isLoading,
      options,
      value,
      hasFixedMessageOption: Boolean(fixedMessageOption),
      disabledFilter,
      selectedItem,
    });

  return (
    <SC.Container className={`${error && 'warning'}`}>
      <MuiAutoComplete
        className="autocomplete-custom-field"
        onChange={(_, data: Option) => onSelectChange(data)}
        isOptionEqualToValue={(option: Option, value) =>
          isOptionEqualToValue(option, value)
        }
        getOptionLabel={(option: Option) => getLabelOption(option)}
        filterOptions={filterOptions}
        onInputChange={(_, value) => onInputChange(value, optionsCount === 0)}
        multiple={false}
        value={value}
        noOptionsText={
          Boolean(noOptionContent) &&
          !isLoading && <AutocompleteMessageOptions {...noOptionContent} />
        }
        loadingText={<AutocompleteLoader {...loaderContent} />}
        loading={isLoading}
        disableClearable={disableClearable}
        renderInput={(params) => {
          return (
            <TextField
              variant="filled"
              value={value ?? inputValue}
              helperText={helperText}
              {...params}
              label={label}
              InputProps={{
                ...params.InputProps,
              }}
            />
          );
        }}
        options={options}
        renderOption={(props, option: Option) => (
          <RenderOption
            key={option?.value}
            fixedMessageOption={fixedMessageOption}
            renderOptionProps={props}
            option={option}
          />
        )}
        popupIcon={<Icons name="IconChevronDown" fill="transparent" />}
        PopperComponent={(props) => (
          <Popper {...props} className={'select-field-popper-custom'} />
        )}
      />
    </SC.Container>
  );
};
