import { Checkbox, tableControllers } from '@flash-tecnologia/hros-web-ui-v2';
import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ApprovalItem } from '../../../pages/TravelConfiguration/Approvals/types';

type ExtendedColumnDef<T> = ColumnDef<T> & {
  accessorKey?: keyof T;
  id: string;
};

export function useTableColumns({
  data,
  columns,
  renderActions,
  onPaginationChange,
  pagination,
  checkboxSelection,
}) {
  const [selected, setAllSelected] =
    tableControllers.useSelectedRows<ApprovalItem>(data.length);

  const tableColumns: ExtendedColumnDef<ApprovalItem>[] = [
    ...(checkboxSelection
      ? [
          {
            id: 'selection',
            header: () => (
              <Checkbox
                checked={selected.allSelected}
                onChange={(e) => setAllSelected(e.target.checked as any)}
                aria-label="Select all"
              />
            ),
            cell: ({ row }) => (
              <Checkbox
                checked={selected.selected.some(
                  (selectedRow) => selectedRow.id === row.original.id,
                )}
                aria-label="Select row"
              />
            ),
          },
        ]
      : []),
    ...columns,
    ...(renderActions
      ? [
          {
            id: 'actions',
            header: 'Ações',
            sticky: 'right',
            size: 100,
            cell: ({ row }) => (
              <Box display="flex" gap={2}>
                {renderActions?.({ item: row.original })}
              </Box>
            ),
          },
        ]
      : []),
  ];

  return tableControllers.useTableColumns({
    columns: tableColumns,
    data,
    onPaginationChange,
    pagination,
  });
}
