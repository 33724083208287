import React from 'react';
import ButtonGroup from '../../common/button-group/button-group.component.js';
import ButtonGroupItem from '../../common/button-group/button-group-item.component.js';
import { enums } from '@legacy-utils/enums';
import useModalStore from '../../../../store/Modal.store';
import ReferenceCodeForm from '../../../../components/ReferenceCodeForm';
import { integrationAck } from '@api/connectOn/connectOn.service';
import { useTravelItemStore } from '../../../../store/RequestTravelItem.store';
import { useTravelStore } from '../../../../store/RequestTravel.store';
import { gritter } from '@legacy-utils/utils';

const Template = (props) => {
  let integrations = props.integrations;
  let allowedActions = props.allowedActions.integrations.apps;
  const openModal = useModalStore((state) => state.openModal);
  const contextId = useTravelStore((state) => state.contextId);
  const closeModal = useModalStore((state) => state.closeModal);
  const airTicket = useTravelItemStore((state) => state.airTicket);
  const setAirTicket = useTravelItemStore((state) => state.setAirTicket);


  let appsDetails = integrations.find(
    (i) => i.backOffice == enums.integrationBackOfficeName.Apps,
  );

  // Pegar o referenceId ou 'NA' se não encontrar
  const referenceId = appsDetails?.referenceId || 'NA';

  let canDispatch =
    allowedActions.canDispatch &&
    (!appsDetails ||
      appsDetails.status === enums.integrationStatus.CancelationIntegrated ||
      appsDetails.status === enums.integrationStatus.EmissionError);
  let canCancel =
    allowedActions.canCancel &&
    appsDetails &&
    (appsDetails.status === enums.integrationStatus.EmissionIntegrated ||
      appsDetails.status === enums.integrationStatus.CancelationError);

  const handleOpenModal = () => {
    let formMethods;

    const onFormReady = (methods) => {
      formMethods = methods;
    };

    const handleSubmit = async () => {
      const isValid = await formMethods.trigger();
      if (isValid) {
        const data = formMethods.getValues();
        try {
          const payload = {
            serviceRequestItemId: props.serviceRequestItemId,
            serviceRequestId: props.serviceRequestId,
            status: 0,
            backOffice: 'Apps',
            message: 'Integração manual da LI (via interface)',
            reference: data.referenceId,
          };
          const { successful, serviceRequestItem } = await integrationAck(
            payload,
            contextId,
          );
          if (successful) {
            setAirTicket(serviceRequestItem);
            closeModal();
            gritter.Success(`Código de referência atualizado com sucesso!`);
          }
        } catch (error) {
          gritter.Error(`Erro ao atualizar referência: ${error}`);
        }
      }
    };

    openModal({
      title: 'Informar código de referência:',
      subTitle:
        'Insira abaixo o código de referência para atualizar na requisição.',
      content: React.createElement(ReferenceCodeForm, { onFormReady }),
      primaryButtonLabel: 'Salvar',
      primaryButtonAction: handleSubmit,
      secondaryButtonLabel: 'Cancelar',
      secondaryButtonAction: closeModal,
    });
  };

  return (
    <div className="exon-apps-integration-details">
      <ButtonGroup
        label="APPS"
        buttonClassName="btn-sm btn-round btn-success align-to-right"
      >
        <ButtonGroupItem
          disabled={!canDispatch}
          iconClassName="icon-ok ace-icon fa fa-history"
          onClick={() => props.onDispatchClick()}
        >
          {appsDetails ? 'Integrar Novamente' : 'Enviar para Integração'}
        </ButtonGroupItem>
        <ButtonGroupItem
          disabled={!canCancel}
          iconClassName="icon-ok ace-icon fa fa-trash"
          onClick={() => props.onDispatchClick()}
        >
          Cancelar Integração
        </ButtonGroupItem>
        <ButtonGroupItem
          iconClassName="icon-ok ace-icon fa fa-trash"
          onClick={handleOpenModal}
        >
          Alterar Ref.
        </ButtonGroupItem>
      </ButtonGroup>
      {appsDetails &&
        appsDetails.status == enums.integrationStatus.EmissionIntegrated && (
          <span>Ref.: {referenceId}</span>
        )}
      {appsDetails &&
        appsDetails.message &&
        (appsDetails.status == enums.integrationStatus.EmissionError ||
          appsDetails.status == enums.integrationStatus.CancelationError) && (
          <span className="exon-icon exon-icon-info-unfilled_disabled exon-tooltip">
            <div className="tooltip">
              <span>{appsDetails.message}</span>
            </div>
          </span>
        )}
    </div>
  );
};

export default Template;
